import { RedocStandalone } from 'redoc';
import test from './test.yaml'
import Navigation from './Navigation/Navigation';
import './App.css'

function App() {
  return (
    <>
      <Navigation/>
      <div className='outer-container'>
      <RedocStandalone specUrl={test}
        options={{
          "hideDownloadButton": true,
          theme: {
            colors: {
              primary: { main: '#007741' },
            }
          }
        }}

      />
    </div>
    </>
  );
}

export default App;
