import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';

const Navigation = () => {
    const [showNav, setShowNav] = useState(false);

    const handleToggleClick = () => {
        setShowNav(!showNav);
    };

    return (
        <nav style={{ backgroundColor: '#007741' }} className="navbar navbar-expand-lg navbar-dark sticky-top">
            <img style={{ marginLeft: '20px' }} src="/vf_full_white_logo.png" alt="VerifiedFirst" height="20" className="pr-3 mr-3" />
            <a style={{ marginLeft: '20px' }} className="navbar-brand m-20p" href="#">
                API Documentation
            </a>
            <button style={{ marginRight:'20px'}} className="navbar-toggler" type="button" onClick={handleToggleClick} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div style={{ marginLeft: '20px' }} className={`collapse navbar-collapse test ${showNav ? 'show' : ''}`} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item active mr-2"><a className="nav-link text-white" href="https://verifiedfirst.com/partners/" target="_self">Verified First Partners</a></li>
                    <li className="nav-item active mr-2"><a className="nav-link text-white" href="https://verifiedfirst.com/partnerships/" target="_self">Become a Partner</a></li>
                    <li className="nav-item active mr-2"><a className="nav-link text-white" href="https://verifiedfirst.com/contact-support/" target="_self">Support</a></li>
                    <li className="nav-item active"><a className="nav-link text-white" href="https://portal.verifiedfirst.com/" target="_self">Portal</a></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;
